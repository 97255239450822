import styled from 'styled-components'

import WebcamComponent from '../../components/Webcam'
import { mq, sizes } from '../../theme'

export const Webcam = styled(WebcamComponent)`
  position: absolute;
  bottom: 2%;
  right: 2%;
  z-index: 100;
  width: 70px;
  opacity: 0;
  pointer-events: none;
  ${mq(sizes.tablet)} {
    width: 200px;
  }
`

export const WebFallback = styled.div<{ isPodium: boolean }>`
  display: none;

  ${mq(sizes.tablet)} {
    display: ${({ isPodium }) => (isPodium ? 'none' : 'block')};
  }
`

export const Experience = styled.div<{ isPodium: boolean }>`
  display: block;

  ${mq(sizes.tablet)} {
    display: ${({ isPodium }) => (isPodium ? 'block' : 'none')};
  }
`

export const Inactivity = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`

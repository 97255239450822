import React, { FC, memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'gatsby-plugin-react-intl'

import { FlowersFinalStepTemplate } from '../../../templates'
import { FlowersFinalStepTemplateProps } from '../../../templates/FlowersFinalStep'
import * as congiguration from '../../../configuration'
import { actions, selectors } from '../../../redux'
import { Screen } from '../../../types/global'

const FlowersPhotoStepContainer: FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  useEffect(() => {
    dispatch(actions.inactivity.startTimer())
  }, [dispatch])

  // selectors

  const mainPhoto = useSelector(selectors.app.mainPhoto)
  const flowersPhotosArray = useSelector(selectors.app.flowersPhotosArray)

  // handlers

  const handleOnPhotoValidate = useCallback<FlowersFinalStepTemplateProps['onPhotoValidate']>(
    (photo) => {
      dispatch(actions.app.setMainWithFlowersPhoto({ url: photo }))
      dispatch(actions.app.setScreen({ screen: Screen.stickersStep }))
      dispatch(actions.inactivity.resetTimer())
    },
    [dispatch]
  )

  const handleOnRetakeClick = useCallback(() => {
    dispatch(actions.app.resetFlowersPhotos())
    dispatch(actions.app.setScreen({ screen: Screen.flowersPhotoStep }))
    dispatch(actions.inactivity.resetTimer())
  }, [dispatch])

  // return

  return (
    <FlowersFinalStepTemplate
      isPodium={congiguration.app.isPodium}
      retakeLabel={intl.formatMessage({ id: 'flowersFinalStep.retake' })}
      validateLabel={intl.formatMessage({ id: 'flowersFinalStep.validate' })}
      mainPhoto={mainPhoto}
      flower1Photo={flowersPhotosArray[0]}
      flower2Photo={flowersPhotosArray[1]}
      flower3Photo={flowersPhotosArray[2]}
      onPhotoValidate={handleOnPhotoValidate}
      onRetakeClick={handleOnRetakeClick}
    />
  )
}

export default memo(FlowersPhotoStepContainer)

import React, { FC, memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl, navigate, changeLocale } from 'gatsby-plugin-react-intl'

import { HomepageTemplate } from '../../../templates'
import * as congiguration from '../../../configuration'
import { actions } from '../../../redux'
import { Screen } from '../../../types/global'
import locales from '../../../intl/locales.json'

const HomepageContainer: FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const handleOnFlowerClick = useCallback(() => {
    dispatch(actions.app.start())
    dispatch(actions.app.setIsWebcamEnabled({ isEnabled: true }))
    dispatch(actions.app.setScreen({ screen: Screen.mainPhotoStep }))
  }, [dispatch])

  return (
    <HomepageTemplate
      isPodium={congiguration.app.isPodium}
      backgroundImagePodium="/images/storybook/templates/homepage/background-podium.jpg"
      backgroundImageWeb="/images/storybook/templates/homepage/background-web.jpg"
      bottleImage="/images/storybook/templates/homepage/bottle.png"
      flowerText={intl.formatMessage({ id: 'home.flowerText' })}
      onFlowerClick={handleOnFlowerClick}
      legalLinkText={intl.formatMessage({ id: 'home.legalLink' })}
      localesSelector={{
        options: locales.data,
        value: intl.locale,
        onChange: (value) => {
          changeLocale(value, '/')
        },
      }}
    />
  )
}

export default memo(HomepageContainer)

import React, { FC, memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'gatsby-plugin-react-intl'

import { FlowersPhotoStepTemplate } from '../../../templates'
import { FlowersPhotoStepTemplateProps } from '../../../templates/FlowersPhotoStep'
import * as congiguration from '../../../configuration'
import { actions, selectors } from '../../../redux'
import { Screen } from '../../../types/global'

type FlowersPhotoStepContainerProps = {
  webcamVideo?: HTMLVideoElement
}

const FlowersPhotoStepContainer: FC<FlowersPhotoStepContainerProps> = ({ webcamVideo }) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  useEffect(() => {
    dispatch(actions.inactivity.startTimer())
  }, [dispatch])

  const flowersPhotosArray = useSelector(selectors.app.flowersPhotosArray)

  const handleOnPhotoTaken = useCallback<FlowersPhotoStepTemplateProps['onPhotoTaken']>(
    (photo) => {
      dispatch(actions.app.setFlowersPhoto(photo))
    },
    [dispatch]
  )

  const handleOnEnd = useCallback(() => {
    dispatch(actions.app.setScreen({ screen: Screen.flowersFinalStep }))
  }, [dispatch])

  return (
    <FlowersPhotoStepTemplate
      isPodium={congiguration.app.isPodium}
      webcamVideo={webcamVideo}
      flowersPreviewPhotoUrls={flowersPhotosArray}
      flowersPreviewText={intl.formatMessage({ id: 'flowersPhotoStep.flowersPreviewText' })}
      onPhotoTaken={handleOnPhotoTaken}
      onEnd={handleOnEnd}
    />
  )
}

export default memo(FlowersPhotoStepContainer)

import styled, { keyframes } from 'styled-components'

import { colors, textStyles } from '../../theme'

const appearAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
export const Inactivity = styled.div`
  opacity: 0;
  animation: ${appearAnimation} 0.5s 0.2s ease-out forwards;
  display: flex;
  flex-direction: column;
  border: 5px solid ${colors.pink};
  max-width: 57rem;
  background-color: ${colors.pureWhite};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem 4rem;
  text-align: center;
`

export const Title = styled.h1`
  ${textStyles.MontserratRegular28Up}
  text-transform: initial;
  color: ${colors.pink};
  text-align: left;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 5rem 0;
`

export const Button = styled.button`
  ${textStyles.MontserratRegular32Up};
  color: ${(props) => (props.color === 'light' ? colors.pinkLigth : colors.pink)};
  background-color: ${colors.pureWhite};
  border-bottom: 2px solid ${(props) => (props.color === 'light' ? colors.pinkLigth : colors.pink)};
  padding-bottom: 1.2rem;
`

export const Subtitle = styled.h2`
  ${textStyles.MontserratRegular18Up};
  text-transform: initial;
  color: ${colors.pink};
`

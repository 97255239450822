import React, { FC, memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'gatsby-plugin-react-intl'

import { FinalStepTemplate } from '../../../templates'
import * as congiguration from '../../../configuration'
import { actions, selectors } from '../../../redux'

const StickersStepContainer: FC = () => {
  const dispatch = useDispatch()

  const intl = useIntl()

  useEffect(() => {
    dispatch(actions.inactivity.startTimer())
  }, [dispatch])
  // selectors

  const photoLocal = useSelector(selectors.app.finalPhotoLocal)
  const photoOnline = useSelector(selectors.app.finalPhotoOnline)
  const isPrinting = useSelector(selectors.app.isPrinting)

  // handlers

  const handleOnPrintClick = useCallback(() => {
    dispatch(
      actions.app.printPhoto({
        url: photoOnline?.url,
        id: photoOnline?.id,
      })
    )
    dispatch(actions.inactivity.resetTimer())
  }, [dispatch, photoOnline?.id, photoOnline?.url])

  const handleOnRestartClick = useCallback(() => {
    dispatch(actions.app.restart())
  }, [dispatch])

  // return

  return (
    <FinalStepTemplate
      isPodium={congiguration.app.isPodium}
      restartText={intl.formatMessage({ id: 'finalStep.restart' })}
      printLabel={intl.formatMessage({ id: 'finalStep.print' })}
      photo={photoLocal}
      canPrint={!!photoOnline?.url}
      isPrinting={isPrinting}
      qrCodeUrl={photoOnline?.url}
      qrCodeText={intl.formatMessage({ id: 'finalStep.qrCodeText' })}
      webText={intl.formatMessage({ id: 'finalStep.webText' })}
      onPrintClick={handleOnPrintClick}
      onRestartClick={handleOnRestartClick}
    />
  )
}

export default memo(StickersStepContainer)

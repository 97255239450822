import React, { FC, memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'gatsby-plugin-react-intl'

import { MainPhotoStepTemplate } from '../../../templates'
import { MainPhotoStepTemplateProps } from '../../../templates/MainPhotoStep'
import * as congiguration from '../../../configuration'
import { actions } from '../../../redux'
import { Screen } from '../../../types/global'

type MainPhotoContainerProps = {
  webcamVideo?: HTMLVideoElement
}

const MainPhotoContainer: FC<MainPhotoContainerProps> = ({ webcamVideo }) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  useEffect(() => {
    dispatch(actions.inactivity.startTimer())
  }, [dispatch])

  const handleOnBottleClick = useCallback(() => {
    dispatch(actions.inactivity.resetTimer())
  }, [dispatch])

  const handleOnRetakeClick = useCallback(() => {
    dispatch(actions.inactivity.resetTimer())
  }, [dispatch])

  const handleOnPhotoValidate = useCallback<
    NonNullable<MainPhotoStepTemplateProps['onPhotoValidate']>
  >(
    (url) => {
      dispatch(actions.inactivity.resetTimer())
      dispatch(actions.app.setMainPhoto({ url }))
      dispatch(actions.app.setScreen({ screen: Screen.flowersPhotoStep }))
    },
    [dispatch]
  )

  return (
    <MainPhotoStepTemplate
      isPodium={congiguration.app.isPodium}
      webcamVideo={webcamVideo}
      instructionsText={intl.formatMessage({ id: 'mainPhotoStep.instructions' })}
      retakeLabel={intl.formatMessage({ id: 'mainPhotoStep.retake' })}
      validateLabel={intl.formatMessage({ id: 'mainPhotoStep.validate' })}
      onPhotoValidate={handleOnPhotoValidate}
      onBottleClick={handleOnBottleClick}
      onRetakeClick={handleOnRetakeClick}
    />
  )
}

export default memo(MainPhotoContainer)

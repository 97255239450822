import React, { FC, memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'gatsby-plugin-react-intl'

import { StickersStepTemplate } from '../../../templates'
import { StickersStepTemplateProps } from '../../../templates/StickersStep'
import * as congiguration from '../../../configuration'
import { actions, selectors } from '../../../redux'
import { Screen } from '../../../types/global'

const StickersStepContainer: FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  useEffect(() => {
    dispatch(actions.inactivity.startTimer())
  }, [dispatch])

  // selectors

  const photo = useSelector(selectors.app.mainWithFlowersPhoto)

  // handlers

  const handleOnPhotoValidate = useCallback<StickersStepTemplateProps['onPhotoValidate']>(
    (photo) => {
      dispatch(actions.app.setFinalPhotoLocal({ url: photo }))
      dispatch(actions.app.setScreen({ screen: Screen.finalStep }))
    },
    [dispatch]
  )

  const handleOnSelectorStickerClick: StickersStepTemplateProps['onSelectorStickerClick'] = useCallback(() => {
    dispatch(actions.inactivity.resetTimer())
  }, [dispatch])

  const handleOnSelectorUndoClick: StickersStepTemplateProps['onSelectorUndoClick'] = useCallback(() => {
    dispatch(actions.inactivity.resetTimer())
  }, [dispatch])

  const handleOnSelectorDeleteClick: StickersStepTemplateProps['onSelectorDeleteClick'] = useCallback(() => {
    dispatch(actions.inactivity.resetTimer())
  }, [dispatch])

  // return

  return (
    <StickersStepTemplate
      isPodium={congiguration.app.isPodium}
      validateLabel={intl.formatMessage({ id: 'stickersStep.validate' })}
      mainPhoto={photo}
      onPhotoValidate={handleOnPhotoValidate}
      stickers={[
        {
          url: '/images/storybook/components/flowers-selector/flower_blue.png',
          id: 'flower_blue',
        },
        {
          url: '/images/storybook/components/flowers-selector/flower_yellow.png',
          id: 'flower_yellow',
        },
        {
          url: '/images/storybook/components/flowers-selector/flower_red.png',
          id: 'flower_red',
        },
        {
          url: '/images/storybook/components/flowers-selector/bottle.png',
          id: 'flower_bottle',
        },
      ]}
      stickersText={intl.formatMessage({ id: 'stickersStep.stickersText' })}
      onSelectorStickerClick={handleOnSelectorStickerClick}
      onSelectorUndoClick={handleOnSelectorUndoClick}
      onSelectorDeleteClick={handleOnSelectorDeleteClick}
    />
  )
}

export default memo(StickersStepContainer)

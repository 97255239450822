import React, { FC } from 'react'

import * as SC from './styled'

export type InactivityProps = {
  className?: string
  title: string
  subtitle: string
  confirmLabel: string
  handleConfirm: () => void
  cancelLabel: string
  handleCancel: () => void
}

const Inactivity: FC<InactivityProps> = ({
  className,
  title,
  subtitle,
  confirmLabel,
  handleConfirm,
  cancelLabel,
  handleCancel,
}) => {
  return (
    <SC.Inactivity className={className}>
      <SC.Container>
        <SC.Title>{title}</SC.Title>
        <SC.Buttons>
          <SC.Button color="light" onClick={handleCancel}>
            {cancelLabel}
          </SC.Button>
          <SC.Button onClick={handleConfirm}>{confirmLabel}</SC.Button>
        </SC.Buttons>
        <SC.Subtitle>{subtitle}</SC.Subtitle>
      </SC.Container>
    </SC.Inactivity>
  )
}

export default Inactivity

import React, { FC, memo } from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'

import { WebFallbackTemplate } from '../../../templates'

const ComingSoonContainer: FC = () => {
  const intl = useIntl()

  return (
    <WebFallbackTemplate
      backgroundImage="/images/storybook/templates/web-fallback/background.jpg"
      title={[
        intl.formatMessage({ id: 'comingSoon.title.1' }),
        intl.formatMessage({ id: 'comingSoon.title.2' }),
      ]}
      text={intl.formatMessage({ id: 'comingSoon.text' })}
    />
  )
}

export default memo(ComingSoonContainer)

import React, { FC, memo, useCallback, useState } from 'react'
import { PageProps } from 'gatsby'
import { useSelector } from 'react-redux'

import { Layout } from '../../components'
import { selectors } from '../../redux'
import { Screen } from '../../types/global'
import * as configuration from '../../configuration'

import ComingSoonContainer from './ComingSoon'
import HomePageContainer from './Homepage'
import MainPhotoStepContainer from './MainPhotoStep'
import FlowersPhotoStepContainer from './FlowersPhotoStep'
import FlowersFinalStepContainer from './FlowersFinalStep'
import StickersStepContainer from './StickersStep'
import FinalStepContainer from './FinalStep'
import WebFallbackContainer from './WebFallback'
import InactivityContainer from './Inactivity'
import * as SC from './styled'

const RootContainer: FC<PageProps> = () => {
  const screen = useSelector(selectors.app.screen)
  const isWebcamEnabled = useSelector(selectors.app.isWebcamEnabled)
  const inactivityTimer = useSelector(selectors.inactivity.timer)

  const [webcamVideo, setWebcamVideo] = useState<HTMLVideoElement | undefined>(undefined)

  // handler
  const handleOnVideoCanPlay = useCallback((video: HTMLVideoElement) => {
    setWebcamVideo(video)
  }, [])

  if (configuration.app.isComingSoon) {
    return (
      <Layout>
        <ComingSoonContainer />
      </Layout>
    )
  }

  return (
    <Layout>
      {isWebcamEnabled && <SC.Webcam onVideoCanPlay={handleOnVideoCanPlay} />}
      <SC.WebFallback isPodium={configuration.app.isPodium}>
        <WebFallbackContainer />
      </SC.WebFallback>
      <SC.Experience isPodium={configuration.app.isPodium}>
        {screen === Screen.home && <HomePageContainer />}
        {screen === Screen.mainPhotoStep && <MainPhotoStepContainer webcamVideo={webcamVideo} />}
        {screen === Screen.flowersPhotoStep && (
          <FlowersPhotoStepContainer webcamVideo={webcamVideo} />
        )}
        {screen === Screen.flowersFinalStep && <FlowersFinalStepContainer />}
        {screen === Screen.stickersStep && <StickersStepContainer />}
        {screen === Screen.finalStep && <FinalStepContainer />}
        {configuration.app.isPodium && inactivityTimer < 30 && (
          <SC.Inactivity>
            <InactivityContainer />
          </SC.Inactivity>
        )}
      </SC.Experience>
    </Layout>
  )
}

export default memo(RootContainer)

import React, { FC, SyntheticEvent, useCallback, useMemo, useRef } from 'react'
import ReactWebcam, { WebcamProps as ReactWebcamProps } from 'react-webcam'

export type WebcamProps = {
  className?: string
  onVideoCanPlay?: (video: HTMLVideoElement) => void
}

const Webcam: FC<WebcamProps> = (props) => {
  const { className, onVideoCanPlay } = props

  // ref

  const $webcam = useRef<ReactWebcam & HTMLDivElement>()

  // memo

  const videoConstraints = useMemo<ReactWebcamProps['videoConstraints']>(
    () => ({
      facingMode: 'user',
      // aspectRatio: { ideal: 1 },
      width: { ideal: 1920 },
      height: { ideal: 1080 },
    }),
    []
  )

  // handlers

  const handleOnUserMedia: ReactWebcamProps['onUserMedia'] = useCallback((stream) => {
    console.log('handleOnUserMedia', stream)
  }, [])

  const handleOnVideoCanPlay: (
    event: SyntheticEvent<HTMLVideoElement, Event>
  ) => void = useCallback(() => {
    if (onVideoCanPlay && $webcam.current?.video) {
      onVideoCanPlay($webcam.current.video)
    }
  }, [onVideoCanPlay])

  const handleOnUserMediaError: ReactWebcamProps['onUserMediaError'] = useCallback((error) => {
    console.log('handleOnUserMediaError', error)
  }, [])

  return (
    <ReactWebcam
      ref={$webcam}
      className={className}
      audio={false}
      mirrored={true}
      videoConstraints={videoConstraints}
      onUserMedia={handleOnUserMedia}
      onUserMediaError={handleOnUserMediaError}
      onCanPlay={handleOnVideoCanPlay}
    />
  )
}

export default Webcam

import React, { FC, memo, useMemo } from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'

import { WebFallbackTemplate } from '../../../templates'

const WebFallbackContainer: FC = () => {
  const intl = useIntl()

  const qrCodeUrl = useMemo(
    () => (typeof window !== 'undefined' ? window.location.origin : undefined),
    []
  )

  return (
    <WebFallbackTemplate
      backgroundImage="/images/storybook/templates/web-fallback/background.jpg"
      title={[
        intl.formatMessage({ id: 'webFallback.title.1' }),
        intl.formatMessage({ id: 'webFallback.title.2' }),
      ]}
      text={intl.formatMessage({ id: 'webFallback.text' })}
      qrCodeUrl={qrCodeUrl}
    />
  )
}

export default memo(WebFallbackContainer)

import React, { FC, memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'gatsby-plugin-react-intl'

import { actions, selectors } from '../../../redux'
import Inactivity from '../../../components/Inactivity'

const InactivityContainer: FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const timer = useSelector(selectors.inactivity.timer)

  const handleConfirm = useCallback(() => {
    dispatch(actions.inactivity.resetTimer())
  }, [dispatch])

  const handleCancel = useCallback(() => {
    dispatch(actions.app.reset())
  }, [dispatch])

  useEffect(() => {
    if (timer <= 0) {
      dispatch(actions.app.reset())
    }
  }, [dispatch, timer])

  return (
    <Inactivity
      title={intl.formatMessage({ id: 'inactivity.title' })}
      subtitle={intl.formatMessage({ id: 'inactivity.subtitle' }, { time: timer })}
      confirmLabel={intl.formatMessage({ id: 'inactivity.confirm' })}
      handleConfirm={handleConfirm}
      cancelLabel={intl.formatMessage({ id: 'inactivity.cancel' })}
      handleCancel={handleCancel}
    />
  )
}

export default memo(InactivityContainer)
